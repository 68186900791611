body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#editor > div {
  border: none !important;
  border-bottom: 1px solid rgb(58, 132, 118) !important;
}
#editor :nth-child(2) {
  border: none !important;
}

#chakra-modal-unsuspend {
  /* background-color: red !important; */
  /* padding: 0px 20px !important; */
  width: 80% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 335px 0px 0px 0px !important;
  max-width: 350px !important;
  /* display: none !important; */
}

#chakra-modal-deletAcMenu {
  width: 100%;
  max-width: 400px;
  margin-top: 12.6rem;
}

#modalBodyOfReasons {
  padding: 0;
  display: flex;
  flex-direction: column;
  /* display: none; */
  justify-content: center;
  align-items: center;
}

#chakra-modal-suspendBoxDiv {
  margin-top: 7rem;
}

#chakra-modal-deleteSection {
  /* display: none; */
  /* padding: 0px 10px; */
  /* width: 90%; */
  max-width: 400px;
  margin-top: 110px;
}

#mainDivOfText > TextFormField {
  display: none !important;
}

.childTitle {
  display: none !important  ;
}

#chakra-modal-addCatBox {
  max-width: 390px;
  margin-top: 110px;
  width: 90%;
}

.chakra-input__left-addon {
  border: none !important;
}

.css-tlfecz-indicatorContainer {
  display: none;
}

.css-319lph-ValueContainer {
  /* width: 100%; */
  padding: 2px 20px 2px 8px !important;
}

.css-14el2xx-placeholder {
  font-size: 13px;
}

.css-byakin {
  border-radius: 25px !important;
  padding: 5px 85px !important;
  width: 50% !important;
  margin: 6px 0px !important;
  color: white !important;
  font-weight: bold !important;
  cursor: pointer !important;
  font-size: 15px !important;
  background-color: rgb(58, 132, 118);
}

.css-17r78lu {
  border-radius: 25px !important;
  padding: 5px 85px !important;
  width: 50% !important;
  margin: 6px 0px !important;
  color: white !important;
  font-weight: bold !important;
  cursor: pointer !important;
  font-size: 15px !important;
  background-color: rgb(255, 0, 0) !important;
}

.chakra-modal__footer {
  display: flex;
  flex-direction: column;
}

.css-zfjltx {
  height: 240px !important;
  overflow: auto !important;
  width: 295px !important;
  margin: 0px 0px opx 15px !important;
}

.css-zfjltx::-webkit-scrollbar {
  display: block !important;
  -webkit-appearance: none !important;
  width: 10px !important;
}
.css-zfjltx::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(60, 133, 120, 0.8) !important;
  /* -webkit-box-shadow: 0 0 1px rgba(60, 133, 120, 0.8) !important; */
}

#chakra-modal-internalNotesMainDiv {
  margin: 170px 0px;
  max-width: 380px;
  width: 90%;
}

.css-zfjltx::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  overflow-y: none !important;
  border-radius: 10px;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media only screen and (min-width: 375px) and (max-width: 428px) {
  #chakra-modal-deletAcMenu {
    max-width: 350px;
  }

  #chakra-modal-deleteSection {
    width: 90%;
  }

  .css-zfjltx {
    /* display: none; */
    width: 250px !important;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media only screen and (min-width: 320px) and (max-width: 375px) {
  #chakra-modal-deletAcMenu {
    max-width: 300px;
  }

  #chakra-modal-suspendBoxDiv {
    width: 92%;
    /* display: none; */
  }

  #chakra-modal-deleteSection {
    /* display: none; */
    /* padding: 0px 10px; */
    width: 90%;
  }

  .css-zfjltx {
    /* display: none; */
    width: 100px !important;
    min-width: 205px !important;

    /* margin-left: 15px; */
  }
}

.MainOuterDiv {
  background-color: #00000029;
  /* width: 100vw; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InnerDiv {
  display: flex;
  max-width: 428px;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../public/Images/Home/background.png");

}

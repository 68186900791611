.mainOuterDiv {
  background-color: #00000029;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainInnerDiv {
  /* display: flex; */
  width: 428px;
  height: 100vh;
  background-color: rgba(60, 133, 120, 0.8);
  overflow: scroll;
  scroll-behavior: none;
  /* align-items: center; */
  /* justify-content: center; */
}

.MainInnerDiv {
  /* display: flex; */
  width: 428px;
  /* height: 100vh; */
  /* background-color: rgba(60, 133, 120, 0.8); */
  background-image: url("../../../public/Images/Home/background.png");
  /* overflow: scroll; */
  scroll-behavior: none;
  /* align-items: center; */
  /* justify-content: center; */
}

.relativePageImage {
  height: 75px;
  width: 85px;
  margin: auto auto;
}

::-webkit-scrollbar {
  display: none;
}

.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  /* margin: 50px 0px 0px 0px; */
  /* justify-content: space-between; */
  /* width: 428PX; */
  /* padding: 0px 20px;*/
}

.homeIcon {
  z-index: 111;
  height: 35px;
  display: block;
  float: left;
  top: 25px;
  left: 25px;
  position: absolute;
  cursor: pointer;
}

.homeBtnDiv {
  position: relative;
}

.profileImg {
  width: 160px;
  height: 205px;
}

.centerheader {
  display: flex;
  flex-direction: column;
}

.header3Section {
  position: relative;
  margin: 30px 0px 0px 0px;
}

.addLinkBtnDiv {
  display: flex;
  border-radius: 25px;
  background-color: #2857f2;
  color: white;
  border: 1px solid #2857f2;
  padding: 8px 15px;
  /* margin-top: 30px; */
  font-size: 15px;
  font-weight: bold;
  right: 30px;
  position: absolute;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.addLinkBtnDiv1 {
  display: flex;
  border-radius: 25px;
  background-color: #2857f2;
  color: white;
  border: 1px solid #2857f2;
  padding: 8px 15px;
  top: 8px;
  right: 30px;
  position: absolute;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.plusicon {
  margin-right: 5px;
}

.searchUser {
  border: 1px solid #3c8578;
  padding: 10px;
  border-radius: 15px;
  width: 370px;
  font-size: 16px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #696969;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  opacity: 1; /* Firefox */
}

.pageTitle {
  font-weight: 600;
  font-size: 18px;
  margin: 5px auto;
  color: white;
}

.filterHeadingIco {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headingOfTable {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px 0px;
}

.tableHeading {
  margin: 0px 15px;
  font-weight: bold;
  font-size: 18px;
}

.userProfile {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0px;
}

.profileDetail {
  background-color: #3c8578;
  border: 1px solid #3c8578;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 5px 10px;
  width: 80%;
  overflow: hidden;
}

.ProfilePhoto {
  display: flex;
  /* width: 10%; */
  justify-content: center;
  align-items: center;
}

.userNames {
  display: flex;
  flex-direction: column;
  /* width: 60%; */
  justify-content: space-evenly;
  align-items: center;
}
.firstlineUser {
  display: flex;
  font-weight: bold;
}

.uName {
  margin: 0px 7px;
  font-size: 20px;
}

.userMailsId {
  display: flex;
  font-weight: bold;
  font-size: 10px;
}

.viewBtn {
  display: flex;
  /* width: 10%; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.view {
  color: black;
  background-color: white;
  font-weight: bold;
  border: 1px solid white;
  padding: 5px 15px;
  border-radius: 43px;
  font-size: 16px;
  cursor: pointer;
}

.indexNumber {
  font-weight: bold;
}

.userMailsId p {
  font-size: 11px;
}
.tableHeadingViews {
  margin: 0px 0px 0px 15px;
}

.filterSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 0px 0px;
  background-color: white;
}

.filterIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

#filter {
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 0px 5px;
  /* background-color: red; */
  padding: 10px 20px;
}

.selectOpt {
  border: none;
  cursor: pointer;
}

.filterInnerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border: 1px solid #707070;
  border-radius: 15px;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .addLinkBtnDiv {
    padding: 3px 6px;
    margin-top: 15px;
    right: 15px;
  }
  .addLinkBtnDiv1 {
    padding: 2px 6px;
    margin-top: 18px;
    right: 17px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .addLinkBtnDiv {
    /* padding: 3px 6px; */
    /* margin-top: 15px; */
    right: 12px;
  }
  .addLinkBtnDiv1 {
    /* padding: 3px 6px; */
    /* margin-top: 15px; */
    right: 20px;
  }
}

.inputGroup {
    color: black;
    font-weight: bold;
    font-size: 15px;
    margin: 7px 0px;
    background-color: white !important;
    border: 1px solid #3C8578 !important;
    border-radius: 15px !important;
    height: 47px !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
  }
  
  .childTitle {
    background-color: white !important;
    padding: 0px !important;
    /* border-radius: 25px !important; */
  }
  
  .inputField {
   
    /* background-color: white !important;
    border: 1px solid #3C8578 !important;
    border-radius: 15px !important; */
  }
  .categoryField{
    width: 100%;
  }
  
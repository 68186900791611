.socialIconDivDrag {
  display: flex;
  flex-direction: column;
  width: 115px;
  /* width: 90%; */
  padding: 5px 0px;
  text-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 17px;
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 17%);
  /* aspect-ratio: 1; */
  justify-content: space-evenly;
  position: relative;
  margin: 10px 10px 15px 5px;
  cursor: pointer;
  /* display: none; */
}

.mainAppDiv {
  /* padding: 20px 30px; */
  overflow: hidden;
  
  /* display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.appImg {
  width: 75px;
  height: 75px;
  border-radius: 15px ;
}

.appName {
  font-weight: 700;
  margin-top: 5px;
  padding: 0px 0px;
  text-align: center;
  font-size: 12px;
}

@media only screen and (min-width: 320px) and (max-width: 340px) {
  .socialIconDivDrag {
    width: 95px;
    margin: 5px 5px 15px 5px;
  }

  .appImg {
    width: 65px;
    height: 65px;
  }

}

@media only screen and (min-width: 340px) and (max-width: 360px) {
  .socialIconDivDrag {
    width: 102px;
    margin: 5px 5px 15px 5px;
  }

  .appImg {
    width: 65px;
    height: 65px;
  }

}@media only screen and (min-width: 360px) and (max-width: 390px) {
  .socialIconDivDrag {
    width: 110px;
    margin: 5px 5px 15px 5px;
  }

  .appImg {
    width: 65px;
    height: 65px;
  }

}

.inputGroup {
  color: black;
  font-weight: bold;
  font-size: 15px;
  margin: 7px 0px 7px 0px;
}

.childTitle {
  /* background-color: white !important; */
  padding: 0px !important;
  /* border-radius: 25px !important; */
}
.inputField {
  height: 47px !important;
   background-color: white !important;
  border: 1px solid #3C8578 !important;
  border-radius: 15px !important;
}

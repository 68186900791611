* {
  margin: 0;
  padding: 0;
}

.icon {
  cursor: pointer;
  border-radius: 5px;
  margin: 0px auto 10px auto;
  /* margin: 15px 15px 5px 15px; */
  max-height: 65px;
  max-width: 80px;
}

.ParticularImgDiv {
  /* margin: 5px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  width: 100%;

}

.imageTitle {
  bottom: 20px;
  color: rgb(0, 0, 0) !important;
  padding: 0px 0px 5px 0px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.blackImageTitle {
  color: black;
  left: 65px;
}
.LinkImageTitle {
  left: 65px;
  bottom: 15px;
}

.MessageImageTitle {
  left: 48px;
  bottom: 15px;
}

.tagImageTitle {
  left: 0;
  top: 0;
  right: 0;
  transform: translate(0%, 5%);
  align-items: center;
  font-size: 42px;
}

.settingImageTitle {
  color: black;
  left: 55px;
  bottom: 6px;
}

.adminImageTitle {
  color: black;
  left: 56px;
  bottom: 10px;
}

.botheContaine{
  padding: 10px 10px 0px 10px;
}

.mainOuterDiv {
  background-color: #00000029;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainInnerDiv {
  /* display: none; */
  /* display: flex; */
  width: 428px;
  height: 100vh;
  background-color: white;
  overflow: scroll;
  scroll-behavior: none;
  /* align-items: center; */
  /* justify-content: center; */
  opacity: 0.5;
}

.mainPopupDiv {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border-radius: 60px;
  /* background-color: rgb(0, 0, 0, 0.8); */
  /* height: 100%; */
  /* width: 428px; */
}
.blackBG {
  background-color: black !important;
  height: 100vh;
}

.innerPopupDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid innerPopupDiv;
  border-radius: 25px;
  width: 100%;
}

.titleForSuspend {
  margin: 15px;
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.Reasons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
  /* padding: 0px 10px; */
}

.reasonText {
  border: 1px solid #3a8476;
  border-radius: 25px;
  padding: 5px 10px;
  width: 90%;
  margin: 6px 0px;
  color: #3a8476;
  font-weight: bold;
  cursor: pointer;
  background-color: white;
}

.descriptionBTN {
  background-color: #3a8476;
  color: white;
  padding: 8px 40px;
  border-radius: 15px;
  border: solid 1px #707070;
  font-weight: bold;
  width: 60%;
}

.submitBtnDIv {
  margin: 15px 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .innerPopupDiv {
    width: 87%;
  }
}

.mainOuterDiv {
    background-color: #00000029;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: none; */
  }
  
  .MainInnerDiv {
    /* display: flex; */
    width: 428px;
    height: 100vh;
    /* background-color: rgba(60, 133, 120, 0.8); */
    background-image: url("../../../public/Images/Home/background.png");
    overflow: scroll;
    scroll-behavior: none;
    position: relative;
    /* align-items: center; */
    /* justify-content: center; */
  }

  .homeIcon {
    height: 35px;
    display: block;
    float: left;
    top: 25px;
    left: 25px;
    position: absolute;
    cursor: pointer;
  }
  
  .goBackIcon {
    height: 35px;
    display: block;
    float: left;
    font-weight: bold;
    top: 75px;
    left: 25px;
    color: white;
    position: absolute;
    cursor: pointer;
  }

  .MainDivCate{
    position: absolute;
    margin-top: 90px;
    width: 100%;
  }

  .searchdiv{
      margin-bottom: 15px !important;
  }
  
  .addLinkBtnDiv {
    display: flex;
    border-radius: 25px;
    background-color: #2857f2;
    color: white;
    border: 1px solid #2857f2;
    padding: 8px 15px;
    /* margin-top: 30px; */
    font-size: 15px;
    font-weight: bold;
    right: 30px;
    top: 22px;
    position: absolute;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    .addLinkBtnDiv{
      padding: 8px 5px;
    }
  }

  .goBackIcon {
    height: 35px;
    display: block;
    float: left;
    font-weight: bold;
    top: 60px;
    left: 25px;
    color: white;
    position: absolute;
    cursor: pointer;
  }

  
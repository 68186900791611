.center {
  justify-content: center;
  display: flex;
  align-items: center;
}

.formcontrolDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 35px;
  z-index: 100;
  width: 40%;
  /* width: 50%; */
  /* background-color: white; */
}

.hsText {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  border: 1px #707070 solid;
  width: 40%;
  border-radius: 20px;
  font-weight: bold;
  padding: 2px 10px;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .hsText {
  width: 50%;
  }
}

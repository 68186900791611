.mainOuterDiv {
  background-color: #ffffff;
  /* width: 100vw; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainInnerDiv {
  display: flex;
  max-width: 428px;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  /* background-image: url(./111.png); */
  overflow: scroll;
  align-items: center;
  justify-content: center;
}

.innerPopupDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  width: 70%;
}

.titleForUnSuspend {
  margin: 15px;
  color: black;
  font-weight: bold;
  font-size: 19px;
}

.choiceOptionDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* margin-bottom: 25px; */
}

.choiceOption {
  border-radius: 25px !important;
  padding: 5px 85px !important;
  width: 50% !important;
  margin: 6px 0px !important;
  color: white !important;
  font-weight: bold !important;
  cursor: pointer !important;
  font-size: 15px !important;
}

.header {
  padding: 10px 10px !important;
  /* width: 40%; */
}

.choiceOptionN {
  background-color: #ff0000;
}

.selectOpt {
  border: none;
  cursor: pointer;
}

.filterSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 0px 0px;
  padding: 0px 30px;
}


.mainAppDiv {
  padding: 20px 30px;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly !important;
}

.allAppsDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  
  .mainAppDiv {
    display: grid !important;
    /* grid-gap: var(--chakra-space-6) !important; */
    grid-gap: 1.5rem !important;
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 20px 25px !important;
  }

}

@media only screen and (min-width: 375px) and (max-width: 425px) {

  .mainAppDiv {
    padding: 20px 15px !important;
    grid-gap: 0.6rem !important;
  }
    
  }
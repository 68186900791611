.innerPopupDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: white; */
  border: 1px solid innerPopupDiv;
  border-radius: 15px;
  width: 90%;
  margin-bottom: 5px;
}

.cal {
  width: 90%;
  border: 1px solid #707070;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 0px 0px 10px 0px;
}

.mainOuterDiv {
  background-color: #00000029;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainInnerDiv {
  /* display: flex; */
  width: 428px;
  height: 100vh;
  /* background-color: rgba(60, 133, 120, 0.8); */
  background-image: url("../../../../public/Images/Home/background.png");
  overflow: scroll;
  scroll-behavior: none;
  position: relative;
  /* align-items: center; */
  /* justify-content: center; */
}

.selectEditing{
  display: none !important;
}
.remoeveborder{
  /* display: none !important; */
  border: 0px;
}


.homeIcon {
  height: 35px;
  display: block;
  float: left;
  top: 25px;
  left: 25px;
  position: absolute;
  cursor: pointer;
}

.goBackIcon {
  height: 35px;
  display: block;
  float: left;
  font-weight: bold;
  top: 75px;
  left: 25px;
  color: white;
  position: absolute;
  cursor: pointer;
}

.homeBtnDiv {
  position: relative;
  z-index: 111;
}

.appImgDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 55px 0px 0px 0px; */
  width: 25%;
  margin: 0 auto;
  margin-top: 55px;
}

.appDeepDetails {
  /* background-color: #3C8578; */
  /* border: 1px solid #3C8578; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* width: 90%; */
  /* padding: 0px 100px; */
  margin: 0px 25px 10px 25px;
  position: relative;
  /* padding-top: 45%;
  padding-bottom: 10% !important; */
  
}

.btnoption {
  display: flex;
  justify-content: flex-start;
  padding: 7px 0px;
}

.appDetailsInner {
  margin: 15px 0px;
  width: 100%;
}

.fandlNamediv,
.notesandDashboardDiv,
.actionDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.notesandDashboardDiv {
  margin: 0px 15px;
}

.fname,
.lname {
  width: 40%;
  margin: 0px;
}

.inputGroup {
  background-color: white !important;
  /* padding: 10px 5px 10px 15px; */
  /* border: 1px solid #707070; */
  border-radius: 25px !important;
  color: black;
  font-weight: bold;
  font-size: 15px;
  margin: 10px 0px;
}
/* .golabalEditing {
    background-color: white !important;
    padding: 10px 5px 10px 15px;
    border: 1px solid #707070;
    border-radius: 15px;
    color: black;
    font-weight: bold;
    font-size: 15px;
    margin: 10px 0px;
  } */

.childTitle {
  background-color: white !important;
  border: 0px !important;
  border-right: 0px !important;
  outline: none !important;
  padding: 0px 5px 0px 10px !important;
  border-radius: 25px !important;
}

::placeholder {
  text-align: left;
}

.inputField {
  border: none !important;
  border-radius: 25px !important;
  padding: 0px 10px 0px 5px !important;
}
.firstInputField {
  padding: 0px 10px 0px 15px !important;
  font-weight: bold;
}

.rBtnDiv {
  display: flex;
  padding-left: 10px;
}

.probtn {
  border: 2px solid #3a8476;
  color: #3a8476;
  background: white;
  min-width: 85px;
  border-radius: 30px;
  font-weight: bold;
  margin-right: 10px;
  padding: 4px 40px;
}

.probtnActive{
  background-color: #fff;
  padding: 5px 40px;
  border: 2px solid #000;
  border-radius: 30px;
  color: #000;
  font-weight: bold;
  font-size: 15px;
  min-width: 85px;
  /* width: 35%; */
  margin-right: 5px;
}

.btnDivFor{
  display: flex !important;
}

.probtn3 {
  background-color: #00c1a0;
  border: 1px solid white;
}

.copyBtnStyle {
  /* background-color: white; */
  display: flex;
  padding: 8px 15px;
  border: 1px solid #707070;
  border-radius: 15px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 15px;
  margin: 5px 0px;
  width: 100%;
  justify-content: center;

  /* margin-right: 10px; */
}

.removeLink {
  background-color: #ff0000 !important;
  border-radius: 25px !important;
  border: 1px solid #707070 !important;
  width: 80% !important;
  margin: 0 auto;
  padding: 25px 0px;

}

.save {
  background-color: #3C8578 !important;
  border-radius: 25px !important;
  border: 1px solid #707070 !important;
  width: 80% !important;
  margin: 0 auto;
  padding: 25px 0px;

}
.saveRemoveBtnMainDiv{
  width: 50%;
  margin: 0 auto;
}

.btnLastOption {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-top: 15px; */
  /* position: fixed;
  bottom: 0px;
  width: 428px; */
  z-index: 1;
  text-align: center;
  padding: 10px 0px;
  flex-wrap: nowrap;
}

.viewrsNumber {
  color: #3c8578;
}

.notes {
  background-color: #f5ec00;
  width: 40%;
  /* color: black; */
  font-weight: bold;
}

.dashboard {
  width: 40%;
}
.delete,
.suspend {
  width: 40%;
}

.MessageDiv {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Message {
  background-color: #00f568;
  color: white;
  width: 40%;
  padding: 8px 10px;
  /* margin-bottom: 10px; */
  /* border: 1px solid #707070;
        border-radius: 10px;
        color: black;
        font-weight: bold;
        font-size: 15px;
        margin: 5px 0px;
        width: 30%; */
}

.delete {
  background-color: #fd0000;
  color: white;
}

.suspend {
  background-color: #fd6b00;
  color: white;
}

.maildiv,
.numberdiv,
.pageViewdiv {
  text-align: left;
  margin-left: 10px;
  margin: 10px 0px;
  height: 40px;
}

.message{
 display: none !important;
}

.fieldNameLabel{
  font-size: 17px;
  font-weight: 700;
}
.radioFormFieldMainDiv{
  display: flex;
  align-items: center;
}
/* .mainTopBtnLogoDiv{
  position: fixed;
  width: 428px;
  top: 0px;
  z-index: 1;
  padding-bottom: 18px;
} */

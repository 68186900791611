
.appBox {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 0px 10px #00000029;
}
.appDetailDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 0px;
}

.appImg{
    width: 75px;
    height: 75px;
}


.appName {
  font-weight: 700;
  margin-top: 5px;
  padding: 0px 0px;
  text-align: center;
  font-size: 12px;

}

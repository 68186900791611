.mainOuterDiv {
  background-color: #00000029;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainInnerDiv {
  /* display: flex; */
  width: 428px;
  height: 100vh;
  background-color: white;
  overflow: scroll;
  scroll-behavior: none;
  /* align-items: center; */
  /* justify-content: center; */
}

.acTypeBtnDiv {
  margin: 10px 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.CStack {
  width: 100%;
}

.acTypeBtn,
.activeacTypeBtn {
  padding: 5px 10px;
  border: 2px solid #3a8476;
  color: #3a8476;
  background: transparent;
  min-width: 85px;
  border-radius: 30px;
  font-weight: 600;
  background-color: white;
  /* margin: 0px 0px; */
  /* -webkit-margin-start: 0rem; */
}

.btnDivFor{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.activeacTypeBtn {
  color: white;
  background-color: #3a8476;
}

::-webkit-scrollbar {
  display: none;
}

.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  /* margin: 50px 0px 0px 0px; */
  /* justify-content: space-between; */
  /* width: 428PX; */
  /* padding: 0px 20px;*/
}

.homeIcon {
  z-index: 111;
  height: 35px;
  display: block;
  float: left;
  top: 25px;
  left: 25px;
  position: absolute;
  cursor: pointer;
}

.homeBtnDiv {
  position: relative;
}

.centerheader {
  display: flex;
  flex-direction: column;
}

.header3Section {
  position: relative;
  margin: 30px 0px 0px 0px;
}
.messageAllbtn {
  display: flex;
  border-radius: 25px;
  background-color: #00f568;
  color: white;
  border: 1px solid #707070;
  padding: 12px 15px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: bold;
  right: 15px;
  position: absolute;
  cursor: pointer;
}

.searchUser {
  border: 1px solid #3c8578;
  padding: 10px;
  border-radius: 15px;
  width: 370px;
  font-size: 16px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #696969;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  opacity: 1; /* Firefox */
}

.profileImage {
  height: 50px;
  width: 50px;
  margin: auto auto;
  border-radius: 5px;
  /* border: 2px solid white; */
  size: auto;
}

.pageTitle {
  font-weight: bold;
  font-size: 20px;
}

.filterHeadingIco {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headingOfTable {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px 0px;
}

.tableHeading {
  margin: 0px 15px;
  font-weight: bold;
  font-size: 18px;
}

.userProfile {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 12px 0px;
  position: relative;
}

.profileDetail {
  background-color: #3c8578;
  border: 1px solid #3c8578;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 5px 10px;
  width: 85%;
  overflow: hidden;
}

.proProfileDetail {
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 5px 10px;
  width: 85%;
  overflow: hidden;
}

.ProfilePhoto {
  display: flex;
  /* width: 10%; */
  justify-content: center;
  align-items: center;
}

.userNames {
  display: flex;
  flex-direction: column;
  /* width: 60%; */
  justify-content: space-evenly;
  align-items: center;
}
.firstlineUser {
  display: flex;
  font-weight: bold;
}

.uName {
  margin: 0px 7px;
  font-size: 20px;
}

.userMailsId {
  display: flex;
  font-weight: bold;
  font-size: 10px;
}

.viewBtn {
  display: flex;
  /* width: 10%; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.view {
  color: black;
  background-color: white;
  font-weight: bold;
  border: 1px solid white;
  padding: 2px 15px;
  border-radius: 43px;
  font-size: 16px;
  cursor: pointer;
}

.indexNumber {
  font-weight: bold;
  font-size: 11px;
  width: 5%;
}

.userMailsId p {
  font-size: 11px;
}
.tableHeadingViews {
  margin: 0px 0px 0px 15px;
}

.filterSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 0px 0px;
  padding: 0px 20px;
}

#filter {
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 0px 5px;
  /* background-color: red; */
  padding: 10px 20px;
}

.selectOpt {
  border: none;
  cursor: pointer;
}

.filterInnerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  border: 1px solid #707070;
  border-radius: 15px;
}

.focus:focus {
  box-shadow: inherit !important;
  border-color: inherit !important;
}

.particularDiv {
  justify-content: center;
  align-items: center;
}

.mainText {
  /* overflow-wrap: anywhere; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  /* width: 15px; */
}

.scrollDiv{
  overflow: scroll;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}
.proShow{
  position: absolute;
    top: -10px;
    background-color: black;
    padding: 0px 11px;
    border-radius: 11px;
    border: 1px solid white;
    font-size: 11px;
    right: 10%;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 375px) and (min-width: 320px) {
  

  .tableHeading {
    margin: 0px 8px;
    font-size: 16px;
  }

  .view {
    padding: 4px 11px;
    border-radius: 43px;
    font-size: 14px;
    cursor: pointer;
  }

  .profileDetail {
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 5px 7px 5px 5px;
    width: 90%;
    min-height: 70px;
  }

  .profileImage {
    height: 42px;
    width: 40px;
  }
}

.inputGroup {
  /* background-color: white !important; */
  /* padding: 10px 5px 10px 15px; */
  /* border: 1px solid #707070; */
  border-radius: 25px !important;
  color: black;
  font-weight: bold;
  font-size: 15px;
  margin: 10px 0px;
}

.childTitle {
  background-color: white !important;
  border-right: 0px !important;
  outline: none !important;
  padding: 0px !important;
  border-radius: 25px !important;
}
.rBtnDiv {
  display: flex;
  padding-left: 10px;
}

/* .MainOuterDiv {
  background-color: #00000029;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InnerDiv {
  display: flex;
  max-width: 428px;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../../public/Images/Home/background.png");

} */

.DashboardTitle {
  font-weight: bold;
}

.headerSection {
  margin: 35px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.optionSection {
  width: 100%;
  display: grid;
  /* grid-template-rows: 1fr 1fr 1fr; */
  grid-template-columns: 1fr 1fr;
  row-gap: 25px;
  column-gap: 35px;
  margin-bottom: 10px;
  justify-content: center;
  padding: 0px 75px;
}
